class Plan {
  constructor({
    id,
    name,
    expiry,
    diamonds,
    vouchers,
    voucherPeriod,
    value,
    image,
  }) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.diamonds = diamonds;
    this.vouchers = vouchers;
    this.voucherPeriod = voucherPeriod;
    this.expiry = expiry;
    this.image = image;
  }

  /// Check if this is valid. It is valid if all required data has been informed.
  isValid(checkId) {
    if (checkId && !this.id) return false;
    if (
      !this.name
      || !this.vouchers
      || !this.voucherPeriod
      || !this.expiry
      || !this.value
    ) return false;

    return true;
  }

  json() {
    return JSON.stringify(this);
  }
}

export default Plan;
