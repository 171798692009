import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';

import { PlanTile } from './components';
import { planService } from '../../services';
import SectionList from '../../components/styles/list/sectionList';

function PlansPage() {
  const [error, setError] = useState();
  const [filter, setFilter] = useState('active');
  const [state, setState] = useState({
    loading: true,
    plans: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (error) return () => { };

    planService.getAll().then((list) => {
      list = list || [];
      const actualDate = moment().format('YYYY-MM-DD');
      if (filter === 'active') {
        list = list.filter((plan) => {
          const planExpiricy = moment(plan.expiry).format('YYYY-MM-DD');
          return moment(planExpiricy).isSameOrAfter(actualDate);
        });
      } else {
        list = list.filter((plan) => {
          const planExpiricy = moment(plan.expiry).format('YYYY-MM-DD');
          return moment(planExpiricy).isSameOrBefore(actualDate);
        });
      }
      const plans = list.map((plan) => (
        <PlanTile
          key={plan.id.toString()}
          plan={plan}
        />
      ));
      setState((oldState) => ({
        ...oldState,
        loading: false,
        plans,
      }));
    }).catch((err) => {
      setError(err);
    });

    return () => { };
  }, [error, filter]);

  // Throwing errors which the AppBoundary can catch
  // Errors thrown inside useEffect are not catched by the boundary
  useEffect(() => {
    if (error) {
      const err = error;
      setError(null);
      throw err;
    }
  }, [error]);

  const handleClick = () => {
    navigate('/plans/new');
  };

  const handleFilter = (fil) => {
    setFilter(fil);
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5"><b>Planos</b></Typography>
          <Button variant="contained" onClick={handleClick}>
            + Adicionar
          </Button>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button
            variant={filter === 'active' ? 'contained' : 'outlined'}
            sx={{ borderRadius: 0 }}
            onClick={() => handleFilter('active')}
          >
            Ativos
          </Button>
          <Button
            variant={filter === 'expired' ? 'contained' : 'outlined'}
            sx={{ borderRadius: 0 }}
            onClick={() => handleFilter('expired')}
          >
            Expirados
          </Button>
        </Box>
        <Box sx={{
          mt: 2, maxHeight: '60vh', bgcolor: 'transparent',
        }}
        >
          <SectionList variant="sectionList">
            {state.plans}
          </SectionList>
        </Box>
      </>
    );
}

export default PlansPage;
