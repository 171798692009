import { ENDPOINTS } from '../constants';
import { User } from '../models';
import { parseResponse, defaultHeaders } from '../utils';

class UserService {
  async getAll(query) {
    const url = ENDPOINTS.parse(ENDPOINTS.USER, query);
    const response = await fetch(url, {
      method: 'GET',
      headers: { ...defaultHeaders(), page: query.page || 1, limit: query.limit || 1000 },
    });
    console.log(response);
    return parseResponse(response);
  }

  async get(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.USER_ID(id), {
      method: 'GET',
      headers: defaultHeaders(),
    });

    return parseResponse(response, User);
  }

  async create(data) {
    const user = new User(data);
    if (!user.isValid()) throw new Error('Invalid user');

    const response = await fetch(ENDPOINTS.USER, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: user.json(),
    });

    return parseResponse(response);
  }

  async update(data) {
    const user = new User(data);
    if (!user.isValid(true)) throw new Error('Invalid user');

    const response = await fetch(ENDPOINTS.USER_ID(user.id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: user.json(),
    });

    return parseResponse(response);
  }

  async delete(id) {
    if (!id) return null;

    const response = await fetch(ENDPOINTS.USER_ID(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

  /** Set user to the cities informed. If a city was not informed and it was already a vendor,
   * it will be removede from it.
   */
  async setVendorToCities({ id, cities }) {
    if (!(id || cities instanceof Array)) throw new Error('missing required arguments: id, cities (array)');

    const response = await fetch(ENDPOINTS.USER_CITY(id), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: JSON.stringify({ cities }),
    });

    return parseResponse(response);
  }

  /** Add user to cities which is not already added. Other cities are not touched. */
  async addVendorToCities({ id, cities }) {
    if (!(id || cities instanceof Array)) throw new Error('missing required arguments: id, cities (array)');

    const response = await fetch(ENDPOINTS.USER_CITY(id), {
      method: 'PATCH',
      headers: defaultHeaders(),
      body: JSON.stringify({ cities }),
    });

    return parseResponse(response);
  }

  /** Remove the vendor from the specific cities informed. Other cities are not touched. */
  async removeVendorFromCities({ id, cities }) {
    if (!(id || cities instanceof Array)) throw new Error('missing required arguments: id, cities (array)');

    const response = await fetch(ENDPOINTS.USER_CITY(id), {
      method: 'DELETE',
      headers: defaultHeaders(),
      body: JSON.stringify({ cities }),
    });

    return parseResponse(response);
  }
}

export default new UserService();
