import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks';

function Sidebar({ logout }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  function isAdmin() {
    return user.roleId === 1;
  }

  function isFranchisee() {
    return user.roleId === 1 || user.roleId === 2;
  }

  function isOwner() {
    return user.roleId === 1 || user.roleId === 3;
  }

  return (
    <List
      component="nav"
      sx={{
        width: '100%',
        maxWidth: 220,
        bgcolor: 'primary.dark',
        height: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Box
        key="logo"
        sx={{
          m: 2,
          height: 80,
          textAlign: 'center',
        }}
      >
        <ListItemButton
          key="/dashboard"
          component="a"
          href="/dashboard"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src="/logo-painel.png" alt="Logo" style={{ display: 'block' }} />
        </ListItemButton>
      </Box>
      <Divider key="divider" sx={{ my: 1 }} />
      {
        isFranchisee() && (
          <ListItemButton
            key="/users"
            component="a"
            href="/users"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Usuários" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isFranchisee() && (
          <ListItemButton
            key="/establishments"
            component="a"
            href="/establishments"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Estabelecimentos" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/cities"
            component="a"
            href="/cities"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Cidades" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/categories"
            component="a"
            href="/categories"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Categorias" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/culinaries"
            component="a"
            href="/culinaries"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Culinárias" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/plans"
            component="a"
            href="/plans"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Planos" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        (isOwner()) && (
          <ListItemButton
            key="/vouchers"
            component="a"
            href="/vouchers"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Vouchers" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/faqs"
            component="a"
            href="/faqs"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="FAQ" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/import"
            component="a"
            href="/import"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Importar Csv" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/importador"
            component="a"
            href="/importador"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Importador Individual" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/pagamentos"
            component="a"
            href="/pagamentos"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Pagamentos (Stripe)" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/logs"
            component="a"
            href="/logs"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="LOGs" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }
      {
        isAdmin() && (
          <ListItemButton
            key="/supporters"
            component="a"
            href="/supporters"
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#C80404',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#C80404',
              },
              ':hover': {
                backgroundColor: '#C80404',
                color: 'white',
              },
            }}
          >
            <ListItemText primary="Apoiadores" sx={{ color: 'primary.light' }} />
          </ListItemButton>
        )
      }

      <ListItemButton
        key="/terms"
        component="a"
        href="/terms"
        sx={{
          '&.Mui-selected': {
            backgroundColor: '#C80404',
          },
          '&.Mui-focusVisible': {
            backgroundColor: '#C80404',
          },
          ':hover': {
            backgroundColor: '#C80404',
            color: 'white',
          },
        }}
      >
        <ListItemText primary="Termos de Uso e Privacidade" sx={{ color: 'primary.light' }} />
      </ListItemButton>

      <ListItemButton
        key="/logout"
        component="a"
        onClick={() => {
          navigate('/');
          logout();
        }}
      >
        <ListItemText primary="Sair" sx={{ color: 'white' }} />
      </ListItemButton>
    </List>
  );
}

Sidebar.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default Sidebar;
