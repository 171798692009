import { ENDPOINTS } from '../constants';
import { parseResponse } from '../utils';

class AuthService {
  async login(credentials) {
    const response = await fetch(ENDPOINTS.AUTH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    return parseResponse(response);
  }

  async requestResetPassword(email) {
    const response = await fetch(ENDPOINTS.REQUEST_RESET, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    return parseResponse(response);
  }

  async validateCode({ email, code }) {
    const response = await fetch(ENDPOINTS.VALIDATE_CODE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, code }),
    });

    return parseResponse(response);
  }

  async resetPassword({ email, code, password }) {
    const response = await fetch(ENDPOINTS.RESET_PASSWORD, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, code, password }),
    });

    return parseResponse(response);
  }
}

export default new AuthService();
