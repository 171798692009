import { ENDPOINTS } from '../constants';
import { parseResponse, defaultHeaders } from '../utils';

class CityService {
  async getCities() {
    // 60 segundos * 60 minutos * 24 horas * 7 dias
    const cacheMaxAge = 7 * 24 * 60 * 60;
    const response = await fetch(ENDPOINTS.CITIES, {
      method: 'GET',
      headers: defaultHeaders(
        { 'cache-control': `max-age=${cacheMaxAge}` },
      ),
    });

    return parseResponse(response);
  }

  async getCity(id, options) {
    // 60 segundos * 60 minutos * 24 horas * 7 dias
    const cacheMaxAge = 7 * 24 * 60 * 60;
    const response = await fetch(ENDPOINTS.CITY(id, options), {
      method: 'GET',
      headers: defaultHeaders(
        { 'cache-control': `max-age=${cacheMaxAge}` },
      ),
      body: JSON.stringify(id),
    });

    return parseResponse(response);
  }

  async addUser(cityId, userId) {
    const response = await fetch(ENDPOINTS.CITY_USER(cityId, userId), {
      method: 'PUT',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

  async removeUser(cityId, userId) {
    const response = await fetch(ENDPOINTS.CITY_USER(cityId, userId), {
      method: 'DELETE',
      headers: defaultHeaders(),
    });

    return parseResponse(response);
  }

  async addVendors(cityId, users) {
    const response = await fetch(ENDPOINTS.CITY(cityId), {
      method: 'PUT',
      headers: defaultHeaders(),
      body: JSON.stringify({ users }),
    });

    return parseResponse(response);
  }

  async removeVendors(cityId, users) {
    const response = await fetch(ENDPOINTS.CITY(cityId), {
      method: 'DELETE',
      headers: defaultHeaders(),
      body: JSON.stringify({ users }),
    });

    return parseResponse(response);
  }
}

export default new CityService();
