import {
  AuthError,
  SessionExpiredError,
  UnauthorizedError,
  UnknownError,
  ImportError,
} from '../errors';

/**
 * Parse the response to json format. If result.ok is false, will throw
 * errors according to the message received from server.
 * If a model is provided, the model's constructor will be used to parse data
 * @param {Response} response Received response
 * @param Model If informed, will use new Model on the parsed response.
 * @returns Data formated
 */
export async function parseResponse(response, Model) {
  const result = await response.json();

  if (!result.ok) {
    switch (result.code) {
      // Auth
      case 4001:
        throw new AuthError(result.message);

      // General
      case 4010:
        throw new SessionExpiredError();
      case 6000:
        throw new ImportError(result.message);
      case 4012:
        throw new UnauthorizedError();

        // Specific Flow

      // Unknown error
      default:
        throw new UnknownError();
    }
  }

  if (Model) {
    return result.data instanceof Array
      ? result.data.map((item) => new Model(item))
      : new Model(result.data);
  }

  return result.data;
}

export function defaultHeaders(otherHeaders) {
  const token = sessionStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
    ...otherHeaders,
  };

  return headers;
}
