import { useEffect, useState, React } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  TextField,
  InputAdornment,
  Box,
  Typography,
  Button,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';

import {
  Search as SearchIcon,
  Diamond,
} from '@mui/icons-material';

import { UserTile, UserDiamonds } from './components';
import { useAuth } from '../../hooks';
import { FRANCHISEE } from '../../constants/roles';
import { userService } from '../../services';

import { PrimaryButton } from '../../components/styles/buttons';

function UsersPage() {
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  function filtersAllowed() {
    if (currentUser.roleId === FRANCHISEE) {
      return {
        2: true, // Franqueado
        3: true, // Restaurante
      };
    }

    return {
      1: true, // Admin
      2: true, // Franqueado
      3: true, // Restaurante
      4: false, // Aplicativo
    };
  }
  const [diamantes, addDiamantes] = useState({
    userPlanId: null,
    open: false,
  });

  console.log(diamantes);

  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 100 },
    { field: 'name', headerName: 'Nome', minWidth: 300 },
    {
      field: 'plan',
      headerName: 'Plano',
      sorteable: false,
      minWidth: 200,
      renderCell: (params) => {
        const sorteado = params.row.BoughtPlans.sort(
          (a, b) => new Date(b.UserPlan.buyDate) - new Date(a.UserPlan.buyDate),
        );
        if (sorteado.length > 0) {
          return (sorteado.map((sort) => <Chip label={sort.name} />));
        }
        return '';
      },
    },
    {
      field: 'tokens',
      headerName: 'Recuperação de Senha',
      minWidth: 200,
      width: 200,
      sortable: false,
      renderCell: (params) => {
        const sorteado = params.row.Tokens.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );
        if (sorteado.length > 0) {
          return (<Chip label={sorteado[0].code} />);
        }
        return '';
      },
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          navigate(`/users/${params.id}`);
        };
        const onClickDiamantes = (e, userPlanId) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(userPlanId);
          addDiamantes({
            userPlanId,
            open: true,
          });
        };
        let plans;
        const sorteado = params.row.BoughtPlans.sort(
          (a, b) => new Date(b.UserPlan.buyDate) - new Date(a.UserPlan.buyDate),
        );
        if (sorteado.length > 0) {
          plans = (sorteado.map((sort) => (
            <Tooltip title={`Adicionar diamantes no ${sort.name} do cliente`}>
              <PrimaryButton
                key="editButton"
                variant="contained"
                fullWidth
                sx={{
                  my: 2,
                  mr: 2,
                  color: 'white',
                }}
                onClick={(event) => onClickDiamantes(event, sort.UserPlan.id)}
              >
                +
                <Diamond sx={{ '&:hover': { backgroundColor: 'transparent!important' } }} />
              </PrimaryButton>
            </Tooltip>
          )));
        }
        return (
          <>
            {plans}
            <PrimaryButton
              key="editButton"
              variant="contained"
              sx={{ my: 2, mr: 2, color: 'white' }}
              onClick={onClick}
            >
              Editar
            </PrimaryButton>
          </>
        );
      },
    },
  ];
  const [pesquisa, setPesquisa] = useState('');
  const [queryName, setQueryName] = useState(true);
  const [queryEmail, setQueryEmail] = useState(false);
  const [error, setError] = useState();
  const [tableData, setTableData] = useState({
    users: [],
    page: 0,
    pageTotal: 1,
    pageSize: 10,
    lading: true,
  });

  useEffect(() => {
    if (error) return;
    console.log(tableData.page);
    const query = {};
    if (queryEmail) {
      query.email = pesquisa;
    }
    if (queryName) {
      query.name = pesquisa;
    }
    userService.getAll({
      ...query,
      page: tableData.page + 1,
      limit: tableData.pageSize,
    }).then((data) => {
      console.log('Data que veio do serviço');
      console.log(data);
      const users = data.rows || [];
      console.log(data);
      setTableData((prev) => ({
        ...prev,
        users,
        pageTotal: data.count,
        loading: false,
      }));
    }).catch((err) => {
      console.log(err);
      setError(err);
    });
  }, [error, tableData.page, tableData.pageSize, pesquisa, queryEmail, queryName]);

  useEffect(() => {
    if (error) {
      const err = error;
      setError(null);
      throw err;
    }
  }, [error]);

  // This is just for throwing error
  const [state, setState] = useState({
    loading: false,
    users: [],
    userList: [],
    filters: filtersAllowed(),
  });

  useEffect(() => {
    const userList = state.users.filter((user) => {
      const { id } = user.Role;

      return state.filters[id];
    }).map((user) => <UserTile key={user.id.toString()} user={user} />);
    setState((oldState) => ({
      ...oldState,
      userList,
    }));
  }, [state.filters, state.users]);

  const handleClick = () => {
    navigate('/users/new');
  };

  const handleCheckName = (event) => {
    setQueryEmail(false);
    setQueryName(event.target.checked);
  };

  const handleCheckEmail = (event) => {
    setQueryName(false);
    setQueryEmail(event.target.checked);
  };

  return state.loading
    ? (
      <CircularProgress />
    )
    : (
      <div style={{ height: '100%', width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
          <Typography variant="h5"><b>Usuários</b></Typography>
          <Button variant="contained" onClick={handleClick}>
            + Adicionar
          </Button>
        </Box>
        <TextField
          id="pesquisa"
          label="Pesquisa"
          sx={{ width: '100%', marginBottom: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            type: 'search',
          }}
          value={pesquisa}
          onChange={(event) => setPesquisa(event.target.value)}
        />
        <FormGroup
          row
          sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            marginBottom: 5,
          }}
        >
          <Typography variant="caption" marginRight={2}>Buscar por</Typography>
          <FormControlLabel control={<Checkbox checked={queryName} onChange={handleCheckName} />} label="Nome" />
          <FormControlLabel control={<Checkbox checked={queryEmail} onChange={handleCheckEmail} />} label="E-mail" />
        </FormGroup>
        <DataGrid
          rows={tableData.users}
          columns={columns}
          pageSize={tableData.pageSize}
          loading={tableData.loading}
          rowCount={tableData.users.length * tableData.pageTotal}
          paginationMode="server"
          pagination
          disableSelectionOnClick
          initialState={{
            useCursorPagination: true,
          }}
          rowsPerPageOptions={[10, 50, 100]}
          onRowClick={(clicked) => console.log(clicked)}
          onPageSizeChange={(newPageSize) => {
            setTableData((prev) => ({ ...prev, pageSize: newPageSize }));
          }}
          page={tableData.page}
          onPageChange={(page) => {
            setTableData((prev) => ({ ...prev, page, loading: true }));
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de mais de ${count}`,
              labelRowsPerPage: 'Resultados por Página',
              footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} linhas selecionadas` : `${count.toLocaleString()} linha selecionada`),
            },
          }}
        />
        {diamantes.open && (
          <UserDiamonds
            open
            handleClose={() => addDiamantes({ userPlanId: null, open: false })}
            userPlan={diamantes}
          />
        )}
      </div>
    );
}

export default UsersPage;
