import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const useColors = {
  background: '#F1F9FF',
  palette: {
    secondary: {
      main: '#6C0202',
      secondary: '#BCE0FD',
    },
    primary: {
      main: '#6C0202',
    },
  },
  buttons: {
    primary: '#6C0202',
    secondary: '#C80404',
  },
};

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6C0202',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            background: 'linear-gradient(0deg, rgba(175,71,0,0) 0%, rgba(255,215,191,1) 50%, rgba(0,212,255,0) 100%);',
            width: 8,
            height: 8,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 16,
            backgroundColor: '#6C0202',
            minHeight: 16,
            border: '3px solid #6C0202',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#6C0202',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#6C0202',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#6C0202',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#6C0202',
          },
        },
      },
    },
    MuiList: {
      variants: [
        {
          props: { variant: 'sectionList' },
          style: {
            borderRadius: '10px',
            backgroundColor: 'white',
            color: '#6C0202',
            padding: '0px',
            overflow: 'auto',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 4px',
            maxHeight: '60vh',
            '& .MuiButtonBase-root:hover': {
              backgroundColor: '#FFD6D6',
            },
          },
        },
        {
          props: { variant: 'establishmentSectionList' },
          style: {
            borderRadius: '10px',
            backgroundColor: 'white',
            color: '#6C0202',
            padding: '0px',
            overflow: 'auto',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 4px',
            maxHeight: '50vh',
            '& .MuiButtonBase-root:hover': {
              backgroundColor: '#FFD6D6',
            },
          },
        },
        {
          props: { variant: 'sidebarList' },
          style: {
            width: '100%',
            maxWidth: 220,
            backgroundColor: '#6C0202',
            height: '100vh',
            overflowY: 'auto',
            color: useColors.palette.primary.main,
            borderRight: `3px solid ${useColors.palette.secondary.secondary}`,
            '& .MuiButtonBase-root:hover': {
              backgroundColor: '#C80404',
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: 'white',
            '& :hover': {
              backgroundColor: '#FFD6D6',
            },
          },
        },
        {
          props: { variant: 'addFloatButton' },
          style: {
            borderRadius: '3px',
            border: `2px solid ${useColors.buttons.primary}`,
            color: useColors.buttons.primary,
            position: 'absolute',
            bottom: '50px',
            right: '40px',
            '& :hover': {
              backgroundColor: '#FFD6D6',
            },
          },
        },
        {
          props: { variant: 'primaryButton' },
          style: {
            borderRadius: '3px',
            border: 'none',
            backgroundColor: useColors.buttons.primary,
            color: 'white',
            '& :hover': {
              backgroundColor: '#FFD6D6',
            },
          },
        },
        {
          props: { variant: 'secondaryButton' },
          style: {
            borderRadius: '3px',
            border: 'none',
            backgroundColor: useColors.buttons.secondary,
            color: useColors.buttons.primary,
            '& :hover': {
              backgroundColor: '#FFD6D6',
            },
          },
        },
      ],
    },
  },
  background: '#FAF7F7',
  typography: {
    bodyTitle: {
      fontWeight: 700,
    },
    h5: {
      color: '#6C0202',
    },
  },
  palette: {
    primary: {
      main: '#6C0202',
      light: '#FFD6D6',
      dark: '#6C0202',
    },
    secondary: {
      main: '#BCE0FD',
    },
    decoration: {
      main: '#C80404',
    },
    error: {
      main: red.A400,
      light: red[100],
    },
    buttons: {
      primary: '#6C0202',
      secondary: '#FFD6D6',
    },
  },
});

export default theme;
